<template>
    <div>
        <div style="width: 100%;float:left;background-color: white; min-height: 100vh">
            <div style="width: 5%;float: left">
                <!-- <Card style="padding-bottom: 0.5rem; margin-top: 2rem;margin-left: 10%">
                     <p slot="title" style="text-align: left;">
                         <Icon type="ios-analytics-outline" />
                         Project information
                     </p>
                     <div class="listItem">
                         Creation Time:
                         <span style="float: right;">{{creationTime}}</span>
                     </div>
                     <div class="listItem">
                         Last updated:
                         <span style="float: right;">{{lastUpdated}}</span>
                     </div>
                     <div class="listItem">
                         Price of executable code:
                         <span style="float: right;">{{ECPrice}}</span>
                     </div>
                     <div class="listItem">
                         Share points/line
                         <span style="float: right;">{{pointsPerLine}}</span>
                     </div>
                     <div class="listItem">
                         Number of investors:
                         <span style="float: right;">{{investorNum}}</span>
                     </div>
                     <div class="listItem">
                         Number of contributors:
                         <span style="float: right;">{{ContributorNum}}</span>
                     </div>
                     <div class="listItem">
                         Number of functions:
                         <span style="float: right;">{{functionNum}}</span>
                     </div>
                     <div class="listItem">
                         Lines of Master:
                         <span style="float: right;">{{LinesOfMaster}}</span>
                     </div>
                     <div class="listItem">
                         Estimated release time:
                         <span style="float: right;">{{EstimatedRT}}</span>
                     </div>
                 </Card>-->
            </div>
            <div style="width: 75%;margin-left:10%;float: left;padding-left: 4%;text-align: left">
                <div style="width: 80%;float: left;margin-left: 3%;margin-top: 30px;margin-bottom: 10px">
                    <div style="font-weight: 600; font-size: 1.35rem;">
                        For further information
                    </div>
                    <span class="CWords" style="font-size: 0.9rem;color: darkgray">Please give your suggestions. You can modify the information you already know or ask for owners for more open information you need. </span>
                </div>
                <Divider></Divider>
                <div style="width: 80%; margin-left:3%;float: left; text-align: left">
                    <div style="width: 100%;float:left;">
                        <div style="font-weight: 600; font-size: 1.1rem; display: inline">
                            Project:
                        </div>
                        <span class="CWords" style="display: inline">{{projectName || "No Info"}}</span>
                    </div>
                    <div style="width: 96%;float:left;margin-top: 10px">
                        <div class="introduction">
                            <div class="introBlock1">
                              <div class="introHeader">
                                <div style="font-weight: 600; font-size: 1.125rem;">
                                    Abstract:
                                </div>
                                <div style="margin-bottom: 15px">
                                    <RadioGroup v-model="button1" type="button">
                                        <Radio label="More information" style="margin-right:10px;"></Radio>
                                        <Radio label="Modify"></Radio>
                                    </RadioGroup>
                                </div>
                              </div>
                              
                              <span class="CWords">{{abstract || "No Info"}}</span>
                              <div v-if="button1==='More information'"
                                   style="width: 100%;float: left;margin-bottom: 30px">
                                 <span class="CWords" style="font-size: 0.8rem;color: darkgray">
                                     Please specify the information you need.
                                     <Icon style="float: right;color: darkgray" size="23" @click="close_click1"
                                           type="ios-backspace-outline"/>
                                 </span>
                                  <Input v-model="pl.moreAbstract" type="textarea" placeholder=""/>
                              </div>
                              <div v-if="button1==='Modify'" style="width: 100%;float: left;margin-bottom: 30px">
                                  <span class="CWords" style="font-size: 0.8rem;color: darkgray">
                                    Give your version about this part.
                                      <Icon style="float: right;color: darkgray" size="23" @click="close_click1"
                                            type="ios-backspace-outline"/>
                                 </span>
                                  <Input v-model="pl.modifyAbstract" type="textarea" placeholder=""/>
                              </div>

                            </div>
                            <div class="introBlock1">
                                <div class="introHeader">
                                  <div style="font-weight: 600; font-size: 1.125rem;">
                                      Environment:
                                  </div>
                                  <div style="margin-bottom: 15px">
                                      <RadioGroup v-model="button2" type="button">
                                          <Radio label="More information" style="margin-right:10px;"></Radio>
                                          <Radio label="Modify"></Radio>
                                      </RadioGroup>
                                  </div>
                                </div>
                                <span class="CWords" style="padding-top: 30px">{{environment || "No Info"}}</span>
                                <div v-if="button2==='More information'"
                                     style="width: 100%;float: left;margin-bottom: 30px">
                                    <span class="CWords" style="font-size: 0.8rem;color: darkgray">
                                       Please specify the information you need.
                                        <Icon style="float: right;color: darkgray" size="23" @click="close_click2"
                                              type="ios-backspace-outline"/>
                                   </span>
                                    <Input v-model="pl.moreEnvironment" type="textarea" placeholder=""/>
                                </div>

                                <div v-if="button2==='Modify'" style="width: 100%;float: left;margin-bottom: 30px">
                                    <span class="CWords" style="font-size: 0.8rem;color: darkgray">
                                      Give your version about this part.
                                        <Icon style="float: right;color: darkgray" size="23" @click="close_click2"
                                              type="ios-backspace-outline"/>
                                   </span>
                                    <Input v-model="pl.modifyEnvironment" type="textarea" placeholder=""/>
                                </div>
                            </div>
                            <div class="introBlock1">
                                <div class="introHeader">
                                  <div style="font-weight: 600; font-size: 1.125rem;">
                                      Dependency:
                                  </div>
                                  <div style="margin-bottom: 15px">
                                      <RadioGroup v-model="button3" type="button">
                                          <Radio label="More information" style="margin-right:10px;"></Radio>
                                          <Radio label="Modify"></Radio>
                                      </RadioGroup>
                                  </div>
                                </div>
                                <span class="CWords">{{dependencies || "No Info"}}</span>
                                <div v-if="button3==='More information'"
                                     style="width: 100%;float: left;margin-bottom: 30px">
                                   <span class="CWords" style="font-size: 0.8rem;color: darkgray">
                                       Please specify the information you need.
                                       <Icon style="float: right;color: darkgray" size="23" @click="close_click3"
                                             type="ios-backspace-outline"/>
                                   </span>
                                    <Input v-model="pl.moreDependency" type="textarea" placeholder=""/>
                                </div>
                                <div v-if="button3==='Modify'" style="width: 100%;float: left;margin-bottom: 30px">
                                    <span class="CWords" style="font-size: 0.8rem;color: darkgray">
                                      Give your version about this part.
                                        <Icon style="float: right;color: darkgray" size="23" @click="close_click3"
                                              type="ios-backspace-outline"/>
                                   </span>
                                    <Input v-model="pl.modifyDependency" type="textarea" placeholder=""/>
                                </div>

                            </div>
                            <div class="introBlock1">
                                <div style="width: 100%;float: left;margin-bottom: 15px">
                                <div class="introHeader">
                                  <div style="font-weight: 600; font-size: 1.125rem;">
                                      Price:
                                  </div>
                                  <div style="margin-bottom: 15px">
                                      <RadioGroup v-model="buttonPrice" type="button">
                                          <Radio label="More information" style="margin-right:10px;"></Radio>
                                          <Radio label="Modify"></Radio>
                                      </RadioGroup>
                                  </div>
                                </div>
                                    <div class="table_unit"
                                         style="width: 100%;height:40px; font-size:1.0125rem; padding-top:10px;margin-bottom:20px;padding-left:3%;text-align:left;float:left; background-color: #F5F5F5">

                                        <div style="width:40%; float:left;display:inline">
                                            Total Profit
                                        </div>
                                        <div style="width:40%;margin-left:3%;float:left;display:inline">
                                            Price
                                        </div>
                                    </div>
                                    <Form style="width: 100%;float:left">
                                        <FormItem
                                                v-for="(item, index) in priceList"
                                                :key="index"
                                                :prop="'items.' + index + '.value'"
                                        >
                                            <div class="table_unit" style="width:100%; float:left;">
                                                <div class="table_unit" style="width:40%; float:left;display:inline">
                                                    <div style="text-align: left;padding-left: 10%" v-if="index===0">0
                                                    </div>
                                                    <Input readonly prefix="logo-usd" v-if="!submit_tag && index>0"
                                                           type="number" v-model="item.profit"
                                                           placeholder="Enter something..."></Input>
                                                    <div style="text-align: left;padding-left: 10%"
                                                         v-if="submit_tag && index >0">
                                                        {{item.profit}}
                                                    </div>
                                                </div>
                                                <div class="table_unit"
                                                     style="width:40%; margin-left:3%;float:left;display:inline">
                                                    <Input readonly prefix="logo-usd" v-if="!submit_tag" type="number"
                                                           v-model="item.price"
                                                           placeholder="Enter something..."></Input>
                                                    <div style="text-align: left;padding-left: 10%" v-if="submit_tag ">
                                                        {{item.price}}
                                                    </div>
                                                </div>
                                                <div readonly v-if="!submit_tag" class="table_unit"
                                                     style="width:10%; margin-left: 3%; float:left;display:inline">
                                                </div>
                                                <hr style="width:100%; float:left; margin-top:12px;height:1px; border:none; background-color:#F5F5F5">
                                                </hr>
                                            </div>
                                        </FormItem>
                                    </Form>
                                </div>
                                <div v-if="buttonPrice==='More information'"
                                     style="width: 100%;float: left;margin-bottom: 30px">
                                    <span class="CWords" style="font-size: 0.8rem;color: darkgray">
                                       Please specify the information you need.
                                        <Icon style="float: right;color: darkgray" size="23" @click="close_clickPrice"
                                              type="ios-backspace-outline"/>
                                   </span>
                                    <Input v-model="pl.morePrices" type="textarea" placeholder=""/>
                                </div>

                                <div v-if="buttonPrice==='Modify'" style="width: 100%;float: left;margin-bottom: 30px">
                                    <span class="CWords" style="font-size: 0.8rem;color: darkgray">
                                      Give your version about this part.
                                        <Icon style="float: right;color: darkgray" size="23" @click="close_clickPrice"
                                              type="ios-backspace-outline"/>
                                   </span>
                                    <div class="table_unit"
                                         style="width: 100%;height:40px; font-size:1.0125rem; padding-top:10px;margin-bottom:20px;padding-left:3%;text-align:left;float:left; background-color: #F5F5F5">

                                        <div style="width:40%; float:left;display:inline">
                                            Total Profit
                                        </div>
                                        <div style="width:40%;margin-left:3%;float:left;display:inline">
                                            Price
                                        </div>
                                    </div>
                                    <Form style="width: 100%;float:left">
                                        <FormItem
                                                v-for="(item, index) in myPriceList"
                                                :key="index"
                                                :prop="'items.' + index + '.value'"
                                        >
                                            <div class="table_unit" style="width:100%; float:left;">
                                                <div class="table_unit" style="width:40%; float:left;display:inline">
                                                    <div style="text-align: left;padding-left: 10%" v-if="index===0">0
                                                    </div>
                                                    <Input prefix="logo-usd" v-if="!submit_tag && index>0" type="number"
                                                           v-model="item.profit"
                                                           placeholder="Enter something..."></Input>
                                                    <div style="text-align: left;padding-left: 10%"
                                                         v-if="submit_tag && index >0">
                                                        {{item.profit}}
                                                    </div>
                                                </div>
                                                <div class="table_unit"
                                                     style="width:40%; margin-left:3%;float:left;display:inline">
                                                    <Input prefix="logo-usd" v-if="!submit_tag" type="number"
                                                           v-model="item.price"
                                                           placeholder="Enter something..."></Input>
                                                    <div style="text-align: left;padding-left: 10%" v-if="submit_tag ">
                                                        {{item.price}}
                                                    </div>
                                                </div>
                                                <div v-if="!submit_tag" class="table_unit"
                                                     style="width:10%; margin-left: 3%; float:left;display:inline">
                                                    <Button long v-if="index===myPriceList.length-1 && index>0"
                                                            @click="handleRemovePrice(index)">Delete
                                                    </Button>
                                                </div>
                                                <hr style="width:100%; float:left; margin-top:12px;height:1px; border:none; background-color:#F5F5F5">
                                            </div>
                                        </FormItem>
                                        <FormItem v-if="!submit_tag">
                                            <div>
                                                <Button type="dashed" long @click="handleAddPrice" icon="md-add"
                                                        style="width: 30%;float: left;margin-left: 35%">Add item
                                                </Button>
                                            </div>
                                        </FormItem>
                                    </Form>

                                </div>
                            </div>
                            <div class="introBlock1">
                                <div class="introHeader">
                                  <div style="font-weight: 600; font-size: 1.125rem;">
                                      Functions:
                                  </div>
                                  <div style="margin-bottom: 15px">
                                      <RadioGroup v-model="button4" type="button">
                                          <Radio label="More information" style="margin-right:10px;"></Radio>
                                          <Radio label="Modify"></Radio>
                                      </RadioGroup>
                                  </div>
                                </div>
                                <div class="input_domain">
                                    <div class="table_unit"
                                         style="width: 100%;height:40px; font-size:1.0125rem; padding-top:10px;margin-bottom:20px;padding-left:3%;text-align:left;float:left; background-color: #F5F5F5">
                                        <div style="width:10%; float:left;display:inline">
                                            Id
                                        </div>
                                        <div style="width:35%; float:left;display:inline">
                                            Name
                                        </div>
                                        <div style="width:35%;margin-left:3%;float:left;display:inline">
                                            Description <strong style="display: inline;font-size: 14px;color:darkgray">(
                                            optional )</strong>
                                        </div>
                                    </div>
                                    <Form style="width: 100%;float:left">
                                        <FormItem
                                                v-for="(item,index) in functionList"
                                                :key="index"
                                                :prop="'items.' + index + '.value'"
                                        >
                                            <div class="table_unit" style="width:100%; float:left;">
                                                <div class="table_unit" style="width:11%; float:left;display:inline">
                                                    Function {{index+1}}
                                                </div>
                                                <div class="table_unit" style="width:35%; float:left;display:inline">
                                                    <div>
                                                        <Input readonly v-model="item.name"></Input>
                                                    </div>
                                                </div>
                                                <div class="table_unit"
                                                     style="width:35%; margin-left:3%;float:left;display:inline">
                                                    <div>
                                                        <Input readonly v-model="item.description"></Input>
                                                    </div>
                                                </div>

                                                <hr style="width:100%; float:left; margin-top:12px;height:1px; border:none; background-color:#F5F5F5">

                                            </div>
                                        </FormItem>
                                    </Form>
                                </div>
                                <div v-if="button4==='More information'"
                                     style="width: 100%;float: left;margin-bottom: 30px">
                                    <span class="CWords" style="font-size: 0.8rem;color: darkgray">
                                       Please specify the information you need.
                                        <Icon style="float: right;color: darkgray" size="23" @click="close_click4"
                                              type="ios-backspace-outline"/>
                                   </span>
                                    <Input v-model="pl.moreFunctions" type="textarea" placeholder=""/>
                                </div>

                                <div v-if="button4==='Modify'" class="input_domain">
                                    <span class="CWords" style="font-size: 0.8rem;color: darkgray">
                                      Give your version about this part.
                                        <Icon style="float: right;color: darkgray" size="23" @click="close_click4"
                                              type="ios-backspace-outline"/>
                                   </span>
                                    <div class="table_unit"
                                         style="width: 100%;height:40px; font-size:1.0125rem; padding-top:10px;margin-bottom:20px;padding-left:3%;text-align:left;float:left; background-color: #F5F5F5">
                                        <div style="width:10%; float:left;display:inline">
                                            id
                                        </div>
                                        <div style="width:35%; float:left;display:inline">
                                            name
                                        </div>
                                        <div style="width:35%;margin-left:3%;float:left;display:inline">
                                            description <strong style="display: inline;font-size: 14px;color:darkgray">(
                                            optional )</strong>
                                        </div>
                                    </div>
                                    <Form style="width: 100%;float:left">
                                        <FormItem
                                                v-for="(item,index) in myfunctionList"
                                                :key="index"
                                                :prop="'items.' + index + '.value'"
                                        >
                                            <div class="table_unit" style="width:100%; float:left;">
                                                <div class="table_unit" style="width:10%; float:left;display:inline">
                                                    Function {{index+1}}
                                                </div>
                                                <div class="table_unit" style="width:35%; float:left;display:inline">
                                                    <div>
                                                        <Input v-model="item.name"></Input>
                                                    </div>
                                                </div>
                                                <div class="table_unit"
                                                     style="width:35%; margin-left:3%;float:left;display:inline">
                                                    <div>
                                                        <Input v-model="item.description"></Input>
                                                    </div>
                                                </div>
                                                <div class="table_unit"
                                                     style="width:8%; margin-left:2%;float:left;display:inline">
                                                    <Button long v-if="index===myfunctionList.length-1 && index >0"
                                                            @click="handleRemove2(index)">Delete
                                                    </Button>
                                                </div>
                                                <hr style="width:100%; float:left; margin-top:12px;height:1px; border:none; background-color:#F5F5F5">
                                            </div>
                                        </FormItem>
                                        <FormItem>
                                            <div>
                                                <Button type="dashed" long @click="handleAdd" icon="md-add"
                                                        style="width: 30%;float: left;margin-left: 35%">Add item
                                                </Button>
                                            </div>
                                        </FormItem>
                                    </Form>
                                </div>

                                <div style="text-align: left;width: 100%;float: left">
                                    <div style="margin: 1rem 1rem; padding: 1rem 1rem; border: #000000 solid 0.0625rem; width: fit-content;">
                                        <img v-if="hierarchyDiagramPath!==''" :src="hierarchyDiagramPath"/>
                                        <span v-if="hierarchyDiagramPath===''">No hierarchy picture here!</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider></Divider>
                <div style="width: 100%;float: left">
                    <div style="width: 80%; margin-left:3%;float: left; text-align: left">
                        <div style="width: 100%;float:left;">
                            <div style="font-weight: 500; font-size: 1.1rem;margin-left: 6%">
                                Suggest Summary:
                            </div>
                            <div class="CWords"
                                 style="font-size: 0.9rem;color: darkgray;margin-left: 6%;margin-top: 1rem;margin-bottom: 1rem">
                                Please summarise your proposal for us.
                            </div>
                            <div style="width: 75%;margin-left: 6%">
                                <Input v-model="pl.summary" type="textarea" :rows="4" placeholder="Enter something..."/>
                            </div>
                        </div>
                        <div style="width:40%;float: left;margin-left: 20%; margin-top: 50px;margin-bottom: 50px">
                            <Button long type="primary" @click="submit">Submit</Button>
                            <Modal v-model="modal1"
                                   title="Submit"
                                   @on-ok="ok1"
                                   @on-cancel="cancel">
                                <p>Submit your application?</p>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--       <div>-->
        <!--           {{pl}}-->
        <!--       </div>-->

    </div>
</template>

<script>
    export default {
        name: "projectFurtherInfo",
        data() {
            return {
                functionList: [],
                priceList: [],
                indexs: 0,
                priceIndex: 0,
                projectName: '',
                userName: '',
                modal1: false,
                creationTime: '',
                lastUpdated: '',
                ECPrice: '',
                pointsPerLine: '',
                investorNum: '',
                ContributorNum: '',
                functionNum: 0,
                LinesOfMaster: 0,
                EstimatedRT: '',
                hpicPath: '',
                txt_summary: '',
                abstract: '',
                environment: '',
                languages: '',
                dependencies: '',
                hierarchyDiagramPath: '',
                myfunctionList: [],
                myPriceList: [],
                pl: {
                    functionList: [],
                    priceList: [],
                    functionNumber: 0,
                    hierarchyDiagramPath: "",
                    hierarchyList: [],
                    id: "",//ID传空值
                    modifyAbstract: "", //修改简介
                    modifyDependency: "",
                    modifyEnvironment: "",
                    moreAbstract: "", //更多简介
                    moreDependency: "",
                    moreEnvironment: "",
                    moreFunctions: "",
                    morePrices: "",
                    projectId: "",
                    projectName: "",
                    reply: "",
                    replyUserId: "",
                    status: false,
                    summary: "",
                    userId: "",
                    username: ""
                },
                button1: '',
                button2: '',
                button3: '',
                button4: '',
                buttonPrice: "",
                defaultList: [],
                file: null,
                // visible: false,
                uploadList: [],
                funcCol: [
                    {
                        title: 'Function Name',
                        key: 'name',
                    }
                ],
                funcData: [],
                imgName: '',
                visible: true,
            }

        },
        methods: {
            loadData() {
                var Tself = this;
                this.pl.userId = this.getCookie("user_id");
                this.pl.username = this.getCookie("user_name");
                this.pl.projectId = this.$route.query.id;
                this.$axios({
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    url: '/project/findById',
                    data: this.pl.projectId
                }).then(function (response) {
                    console.log(response);
                    var data = response.data.data.items;
                    Tself.pl.projectName = data.name;
                    Tself.environment = data.environment;
                    Tself.pl.modifyEnvironment = data.environment;
                    Tself.projectName = data.name;
                    Tself.abstract = data.abstractInfo;
                    Tself.pl.modifyAbstract = data.abstractInfo;
                    Tself.languages = data.languages;
                    Tself.dependencies = data.dependencies;
                    Tself.pl.modifyDependency = data.dependencies;
                    Tself.creationTime = data.creationTime;
                    Tself.hpcPath = data.hierarchyDiagramPath;
                    Tself.functionList = data.functionList;
                    Tself.investorNum = data.investorNumber;
                    let vt = data.functionList;
                    Tself.hierarchyDiagramPath = data.hierarchyDiagramPath;
                    Tself.funcData = [];
                    for (var i = 0; i < vt.length; i++) {
                        Tself.funcData.push({name: vt[i]});
                    }
                    let funcList = [];
                    let funcList2 = [];
                    for (let i = 0; i < data.functionList.length; i++) {
                        var func = data.functionList[i].split("---")
                        funcList.push({
                            name: func[0],
                            description: func[1]
                        })
                        funcList2.push({
                            name: func[0],
                            description: func[1]
                        })

                    }
                    let priceList = [];
                    let priceList2 = [];
                    for (let i = 0; i < data.priceList.length; i++) {
                        let str = data.priceList[i].split("---")
                        priceList.push({
                                profit: str[0],
                                price: str[1]
                            }
                        )
                        priceList2.push({
                                profit: str[0],
                                price: str[1]
                            }
                        )
                    }
                    Tself.functionList = funcList
                    Tself.myfunctionList = funcList2

                    Tself.priceList = priceList
                    Tself.myPriceList = priceList2
                }).catch((error) => {
                    console.log(error)
                    this.$Message.error(error.message);
                })
            },
            getCookie(cname) {
                let name = cname + "=";
                let ca = document.cookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i].trim();
                    if (c.indexOf(name) !== -1) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            submit() {
                this.modal1 = true;
            },
            checkType() {
                if (this.button1 === 'More information') {
                    this.pl.modifyAbstract = ""
                } else if (this.button1 === 'Modify') {
                    this.pl.moreAbstract = ""
                } else {
                    this.pl.modifyAbstract = ""
                    this.pl.moreAbstract = ""
                }

                if (this.button2 === 'More information') {
                    this.pl.modifyEnvironment = ""
                } else if (this.button2 === 'Modify') {
                    this.pl.moreEnvironment = ""
                } else {
                    this.pl.modifyEnvironment = ""
                    this.pl.moreEnvironment = ""
                }

                if (this.button3 === 'More information') {
                    this.pl.modifyDependency = ""
                } else if (this.button3 === 'Modify') {
                    this.pl.moreDependency = ""
                } else {
                    this.pl.modifyDependency = ""
                    this.pl.moreDependency = ""
                }

                if (this.buttonPrice === 'More information') {
                    this.myPriceList = []
                } else if (this.buttonPrice === 'Modify') {
                    this.pl.morePrices = ""
                } else {
                    this.pl.morePrices = ""
                    this.myPriceList = []
                }

                if (this.button4 === 'More information') {
                    this.myfunctionList = []
                } else if (this.button4 === 'Modify') {
                    this.pl.moreFunctions = ""
                } else {
                    this.pl.moreFunctions = ""
                    this.myfunctionList = []
                }
            },
            ok1() {
                this.checkType()
                let fcl = this.myfunctionList.map(a => a.name + "---" + a.description)
                this.pl.functionNumber = this.myfunctionList.length;
                this.pl.functionList = fcl;
                if (this.myPriceList.length !== 0) {
                    this.myPriceList[0].profit = 0;
                }
                let prl = this.myPriceList.map(a => a.profit + "---" + a.price)
                this.pl.priceList = prl;
                //判断每个部分提交的是more还是modify
                //2c929f4a7853b4a3017855a31bde0006
                this.$axios.post(
                    "/moreInformation/addMoreInformation",
                    this.pl
                ).then((response) => {
                    console.log(response)
                    this.$Message.success('Success!');
                    location.href = "/#/projectDetail?id=" + this.pl.projectId;
                }).catch((error) => {
                    this.$Message.error(error.message);
                });
            },
            handleAdd() {
                this.indexs++;
                this.myfunctionList.push({
                    name: '',
                    index: this.indexs,
                    description: " "
                });
            },
            handleAddPrice() {
                this.priceIndex++;
                this.myPriceList.push({
                    profit: '',
                    price: '',
                });
            },
            handleRemovePrice(index) {
                // this.priceIndex --;
                this.myPriceList.pop();
            },
            handleRemove2(index) {
                // this.indexs --;
                this.myfunctionList.pop();
            },
            cancel() {

            },

            initial_fk() {
                this.projectName = 'projectName';
                this.userName = 'userName';
                this.abstract = `The Visual Understanding Environment (VUE) project at Tufts' Academic Technology department provides faculty and students with tools to successfully integrate digital resources into their teaching and learning. VUE provides a visual environment for structuring, presenting, and sharing digital information and an OKI-compliant software bridge for connecting to FEDORA-based digital repositories.`;
                this.environment = `Software libraries, File systems, Web sites, Human factors, Educational products, Software tools`;
                this.languages = 'java: 63%, vue: 37%';
                this.dependencies = `Visual Understanding Environment, VUE project, Tufts Academic Technology, teaching, learning, OKI-compliant software bridge, FEDORA-based digital repositories`
                this.funcData = [
                    {
                        name: 'RL-Adventure',
                        description: `This is easy-to-follow step-by-step Deep Q Learning tutorial with clean readable code.`,
                        date: '2016-10-03',
                        lines: '38431'
                    },
                    {
                        name: 'Neural Episodic Control',
                        description: `First, I recommend to use small test problems to run experiments quickly. Then, you can continue on environments with large observation space.`,
                        date: '2018-10-03',
                        lines: '20572'
                    },
                    {
                        name: 'Rainbow',
                        description: `Combining Improvements in Deep Reinforcement Learning`,
                        date: '2020-10-03',
                        lines: '16390'
                    },
                    {
                        name: 'Noisy Networks for Exploration',
                        description: `Carefully go through the paper. Try to see what is the problem the authors are solving. `,
                        date: '2019-10-03',
                        lines: '7989'
                    }
                ];
            },
            close_click1() {
                if (this.button1 !== '') {
                    this.button1 = ''
                }
            },
            close_click2() {
                if (this.button2 !== '') {
                    this.button2 = ''
                }
            },
            close_click3() {
                if (this.button3 !== '') {
                    this.button3 = ''
                }
            },
            close_click4() {
                if (this.button4 !== '') {
                    this.button4 = ''
                }
            },
            close_clickPrice() {
                if (this.buttonPrice !== '') {
                    this.buttonPrice = ''
                }
            },
        },
        mounted() {
            //this.initial_fk();
            this.loadData();
        }
    }
</script>

<style scoped>
    .introBlock1 {
        margin-top: 50px;
    }
    .introHeader{
      width:100%;
      display:flex;
      justify-content: space-between;
    }

    .demo-upload-list {
        display: inline-block;
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        border: 1px solid transparent;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        position: relative;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
        margin-right: 4px;
    }

    .demo-upload-list img {
        width: 100%;
        height: 100%;
    }

    .demo-upload-list-cover {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, .6);
    }

    .demo-upload-list:hover .demo-upload-list-cover {
        display: block;
    }

    .demo-upload-list-cover i {
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin: 0 2px;
    }

</style>
